import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';

import { Agent } from '@app/models/user/agent.model';
import { environement } from '@environments/environment';
import { AgentDetail } from '@models/user/agent-detail.model';
import { PageRequest, PageResponse } from 'pf-ui';
import { MessageService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { BaseService } from './base.service';
import { PageableResponse } from '@models/common/pageable-response.model';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends BaseService {
  signalCurrent = signal<AgentDetail | null>(null);
  signalList = signal<PageResponse<AgentDetail> | undefined>(undefined);

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  list(event?: PageRequest): Observable<PageResponse<AgentDetail>> {
    return this.executeRequest(
      this.http.get<PageableResponse<AgentDetail>>(
        `${environement.BACKEND_URL}/agents`,
        {
          params: this.getHttpParams({ ...event }),
        },
      ),
    ).pipe(
      this.mapPageableResponseToPageResponse<AgentDetail>(event),
      tap((pageResponse) => {
        this.signalList.set(pageResponse);
      }),
    );
  }

  getMe(): Observable<AgentDetail> {
    return this.executeRequest(
      this.http.get<AgentDetail>(`${environement.BACKEND_URL}/me`),
    ).pipe(
      tap((val) => {
        this.signalCurrent.set(val);
      }),
    );
  }

  patch(body: Agent): Observable<AgentDetail> {
    return this.executeRequest(
      this.http.patch<AgentDetail>(
        `${environement.BACKEND_URL}/agents/${body.id}`,
        body,
      ),
    );
  }
}
