import { RoleDetail } from '@models/user/role-detail.model';
import { Authority } from '@models/user/authorities.model';
import { AuditingItem } from '@models/question/auditing.model';
import { Island } from '../island/island.model';

export type AgentDetail = {
  id: string;
  publicId?: string;
  sub?: string;
  firstName?: string;
  lastName?: string;
  island?: Island;
  agentRoles?: RoleDetail[];
} & AuditingItem;

export function hasAuthority(
  agent: AgentDetail | null,
  authorityName: string | null,
): boolean {
  if (!agent?.agentRoles || authorityName == null) {
    return false;
  }

  return agent.agentRoles.some(
    (role: RoleDetail) =>
      role.roleAuthorities.some(
        (a: Authority) =>
          a.authority === authorityName || a.name === authorityName,
      ) || role.name === authorityName,
  );
}

export function hasAnyAuthorities(
  agent: AgentDetail | null,
  authorities: string[] | null,
): boolean {
  return (
    authorities?.some((authorityName) => hasAuthority(agent, authorityName)) ??
    false
  );
}
